import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import BallotIcon from '@mui/icons-material/Ballot';
import { useNavigate } from "react-router-dom";
import { useMoodle } from "../../contexts/moodle";

import cert from "../../assets/images/school-certificate.png";
import {Tooltip} from "@mui/material";
import {odinBaseUrl, surveyUrl} from "../../services/api";
import {getCertificate} from "../../services/odin/courses";
import axios from "axios";
import {SNACKTYPES, useSnack} from "../../contexts/snack";
import {CustomSpinner} from "../../components/Loader";
import {useOdin} from "../../services/odin/auth";

export default function BasicTable({ headers, courses, grades }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#212735", color: "#fff" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((h, idx) => {
                return (
                  <TableCell
                    key={idx}
                    scope="col"
                    sx={{
                      color: "#b2b2b2",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      textAlign: idx === 1 || idx === 2 || idx === 3 || idx === 4 || idx === 5 || idx === 6 ? "center" : "unset",
                    }}
                  >
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {courses &&
            courses.map((row, idx) => {
              //let grade = grades.filter((g) => g.courseid === row.course.platformID)[0].grade;
              return <RowItem grade={0} course={row} key={idx} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const RowItem = ({ course, grade }) => {
  const moodle = useMoodle();
  const navigation = useNavigate();

  const [feedback, setFeedback] = React.useState(null);

  const [certificate, setCertificate] = React.useState(null);
  const [mensaje, setMensaje] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const userOdinData = useOdin();
  const [dj, setDj] = React.useState(false);
  const [, handleSnack] = useSnack();
  const [data, setData] = React.useState({
    name: course.course.name,
    start: course.init_date,
    end:  course.end_date,
    dj:course.procen_franquiciable,
    certificate:course.descargar_certificado,
    grade:course.grade.finalGrade,
    course:course,
    status_course:course.status_course
  });

  React.useEffect(() => {
    setData({
      name: course.course.name,
      start: course.init_date,
      end: course.end_date ,
      dj:course.procen_franquiciable,
      certificate:course.descargar_certificado,
      grade:course.grade.finalGrade,
      course:course,
      status_course:course.status_course

    });

    moodle
      .getProgressDetail({ course })
      .then(({ feed, cert }) => {
        // console.log(feed);
        setDj(dj);
        setFeedback(feed);
        //setCertificate(cert);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [course]);

  const handleDetailNavigation = () =>
    navigation(`/listcourses/progress/${course.id}`, {
      state: { name: data.name },
    });

  const handleFeedbackNavigation = (data) => {
    /*navigation("/moodle-web-view", {
      state: { url: feedback },
    });*/
      let datosPersonales = JSON.parse(localStorage.getItem("loginUserOdin"));
      let params = [];
      params.push({
        rut: datosPersonales.user.rut_alumno,
        nombre: datosPersonales.user.nombre_alumno,
        apellidos: datosPersonales.user.apellido_paterno_alumno,
        data: data,
        survey_id: data.id_encuesta,
        otec_holding: data.id_otec_holding,
      });
      let url = surveyUrl + btoa(JSON.stringify(params));
      window.open(`${url}`, "_blank");

  };

  const handleOpenCertificate = (data) => {
    /*if (feedback === true) {
      window.open(certificate);
    }*/
      setLoading(true)
    let dataUserOdin = localStorage.getItem('loginUserOdin')
    const userOdin = JSON.parse(dataUserOdin)
    let urlOdin = odinBaseUrl + 'api/alumnos/certificado/' + data
    axios.get(
        `${urlOdin}`, {
          headers: {
            'Authorization': `Bearer  ${userOdin.api_token}`
          }
        }
    )
        .then(response => {
          //console.log(response.data.data, 'response.data')
          //return response.data
          setCertificate(response.data);
          setMensaje(response.data.message)
          let copiaResponse: any = response;
            setLoading(false)

          if (response && !copiaResponse.estado !== 'ERROR') {
            window.open(`${copiaResponse.data.url}`, "_blank");
          } else {
            handleSnack({
              type: SNACKTYPES.error,
              payload: {
                message: copiaResponse.data.message,
              },
            });
          }
          // setcourses(.data);
        })
        .catch(error => {
            setLoading(false)

          //setError(error);
            handleSnack({
                type: SNACKTYPES.error,
                payload: {
                    message: error.response.data.message,
                },
            });
          console.error('Error fetching data:', error.response.data.message, error);
        });

    //console.log(url)
  };
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }
  const handleOpenDj = () => {
    if (feedback === true) {
      window.open(certificate);
    }
  };
    if (userOdinData.loading || loading) {

        return <CustomSpinner />;
    }
  return (
    <>
      <TableRow
        key={data.name}
        sx={{
          "&:last-child td, &:last-child th": { color: "#b2b2b2" },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          //onClick={handleDetailNavigation}
          sx={{ color: "#b2b2b2", width: "35%" }}
        >
          {data.name}
        </TableCell>
        <TableCell align="center" sx={{ color: "#b2b2b2" }}>
            {data.status_course === 1 ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    -
                </div>
            ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {data.grade}
                </div>
            )}
        </TableCell>
        <TableCell align="center" sx={{ color: "#b2b2b2" }}>
          {formatDate(data.start)}
        </TableCell>
        <TableCell align="center" sx={{ color: "#b2b2b2" }}>
          {formatDate(data.end)}
        </TableCell>
        <TableCell align="center" sx={{ color: "#b2b2b2", textAlign: "center" }}>
          {data.dj === 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                -
              </div>
          ) : (
              <div>
                <a href={"https://lce.sence.cl/CertificadoAsistencia/#no-back-button"} target={"_blank"}>
                  <Tooltip title="Descargar Dj">
                    <DownloadForOfflineIcon onClick={() => handleOpenDj()} sx={{ color: "#b2b2b2", fontSize: 30 }} />
                  </Tooltip></a>
              </div>
          )}
        </TableCell>
        <TableCell align="center"  sx={{ color: "#b2b2b2", textAlign: "center" }}>
          {feedback === true ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DoneIcon sx={{ color: "#16a34a", fontSize: 30 }} />
              </div>
          ) : (
              <div>
                <Tooltip title="Realizar Encuesta">
                  <BallotIcon
                      onClick={() => handleFeedbackNavigation(data.course)}
                      sx={{
                        color: "#b2b2b2",
                        fontSize: 28,
                        transition: ".3s",
                      }}
                  />
                </Tooltip>
              </div>
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{ color: "#b2b2b2", cursor: "pointer" }}
          onClick={() =>handleOpenCertificate(data.course.course_taken)}
        >
          {data.certificate === 0 || data.status_course === 1? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                -
              </div>
          ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip title="Descargar">
            <img
              src={cert}
              width={70}
              //style={{ opacity: feedback === true ? 1 : 0.6 }}
              alt="..."
            />
            </Tooltip>
          </div> )}
        </TableCell>
      </TableRow>
    </>
  );
};
/**/
