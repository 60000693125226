import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import eap_banner_desktop from "../../assets/images/img-banner-eap.jpg";
import eap_banner_mobile from "../../assets/images/img-banner-eap-mobile.jpg";
import "./styles.css";
import {SNACKTYPES, useSnack} from "../../contexts/snack";
import { loginDiagnostic } from "../../services/diagnostico/auth";
import { diagnosticoWebUrl } from "../../services/api";
export const CarouselBanner = ({ array }) => {
  const [eap /*, setEap */] = useState(true);
  const [bannerArray, setBannerArray] = useState([...array]);
  const [urlDiagnostico,setUrlDiagnostico ] = useState(null);
  const [tokenDiagnostico,setUserTokenDiagnostico ] = useState(null);
  const [tokenDiagnosticoError,setUserTokenDiagnosticoError ] = useState(null);
  const [, handleSnack] = useSnack();
  let eap_banner = {
    //link_ads: "https://www.merkademia.com/mis-diagnosticos",
    link_ads: urlDiagnostico,

    // link_ads: "http://localhost:3000/mis-diagnosticos",
    images: {
      image_desktop: eap_banner_desktop,
      image_mobile: eap_banner_mobile,
    },
  };
  useEffect(() => {
    /* agregar logica para desplegar o no banner de Evaluacion de aprendizajes previos (eap) */
    if (eap) {
      setBannerArray([eap_banner, ...array]);
      // console.log("final banner array", [eap_banner, ...array]);
    }
    //eslint-disable-next-line
  }, [array]);

  const asyncAbrirDiagnostico = async () => {
    let token_diagnostico = localStorage.getItem("diagnostico");
    if (token_diagnostico) {
      let url = diagnosticoWebUrl +'auth/login/'+ token_diagnostico
      window.open(url, '_blank')
    }
    else if(tokenDiagnosticoError == 403) {
      return handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Usted no tiene evaluaciones de aprendizajes privios disponibles aún",
        },
      });
    }
  };
  useEffect(() => {
    let token_diagnostico = localStorage.getItem("diagnostico");
    if (token_diagnostico) {
      setUserTokenDiagnostico(token_diagnostico)
    }else {
       loginDiagnostic().then((res) => {
         token_diagnostico = btoa(res.data)
         setUserTokenDiagnostico(token_diagnostico)
         localStorage.setItem('diagnostico', token_diagnostico)
      }).catch(error => {
         setUserTokenDiagnosticoError(error.response.status)

       });;
    }
    let url = diagnosticoWebUrl +'auth/login/'+ token_diagnostico
    //eap_banner.link_ads = url
    setUrlDiagnostico(url)
  }, [array]);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        {bannerArray.map((e, idx) => (
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={idx}
            className={idx === 0 ? "active" : ""}
            aria-current="true"
            aria-label={`Slide ${idx}`}
            key={idx}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {/* se cambio > 0 => > 1, mientras no exista logica de eap */}
        {bannerArray.length > 0 ? (
          bannerArray.map((element, index) => (
            <div
              className={index === 0 ? "carousel-item active" : "carousel-item"}
              key={index}
              onClick={asyncAbrirDiagnostico}
            >
              <img
                src={
                  window.screen.width > 820
                    ? element.images.image_desktop
                    : element.images.image_mobile
                }
                className="d-block w-100 img-banner"
                alt={`imagen banner ${index}`}
              />
            </div>
          ))
        ) : (
          <CardBannerSkeleton />
        )}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

const CardBannerSkeleton = () => {
  return (
    <Skeleton
      baseColor="#cccccc"
      className="img-banner"
      style={{
        opacity: 0.5,
        width: "100%",
        marginBottom: "1rem",
        height: "400px",
      }}
    />
  );
};
