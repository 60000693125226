export const baseURL = "https://lms.merkademia.com/";
export const tipddytoken = "359318cb850de5ec1b415b065b979658";
export const moodleURL = `${baseURL}webservice/rest/server.php?wstoken=`;
export const dashboardURL =  "https://dashboard-moodle.iapp.cl/wp-json/v2/public";
export const iappUrl = "https://merkademia-rnu.iapp.cl/api/rnu";

//export const diagnosticoApiUrl = "http://127.0.0.1:8000/api/login";
//export const diagnosticoWebUrl = "http://localhost:4200/";

export const diagnosticoApiUrl = "https://admin-diagnosticos.edutecno.cl/api/login";
export const diagnosticoWebUrl = "https://diagnosticos.asecapacitacion.cl/";
export const surveyUrl= "https://encuestas.educampus.cl/encuestas/public/";

//export const odinBaseUrl='http://localhost:8200/'
export const odinBaseUrl='https://thor-g-01.edutecno.cl/'
//export const odinApiBaseUrl='http://localhost:8200/api/'
export const odinApiBaseUrl='https://thor-g-01.edutecno.cl/api/'
export const oOdinAppClientId='4'
export const odinAppClientSecret='4No9oMBoxN5gJBxjOAGQlILrndJxIUD5NWenFUIk'
export const odinAppGrantType='client_credentials'
