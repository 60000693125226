import axios from "axios";
import { baseURL, moodleURL, tipddytoken } from "../api";
import { useOdin} from "../odin/auth";

export const getCurrentUser = async ({ token, username }) => {
  const resp = await axios.get(
    `${moodleURL}${token}&wsfunction=core_user_get_users_by_field&field=username&values[0]=${username}&moodlewsrestformat=json`
  );
  const user = resp.data[0];
  return { ...user, token };
};

/* auth_userkey_request_login_url */

const login = async ({ username, password }) => {
  const respAuth = await axios.get(
    `${baseURL}login/token.php?username=${username}&password=${password}&service=moodle_mobile_app`
  );
  const data = respAuth.data;
  if (data.error) {
    throw new Error(data.error);
  }
  const { token } = data;
  return { data, token };
};

export const requestAutoLogin = async (username) => {
  let urlAutoLogin = `${moodleURL}${tipddytoken}&moodlewsrestformat=json&wsfunction=auth_userkey_request_login_url&user[username]=${username}`;
  try {
    const { data } = await axios.get(`${urlAutoLogin}`);
    console.log("requestAutoLogin data:", data);
    let key = data.loginurl.split("=")[1];
    return key;
  } catch (e) {
    throw new Error(e);
  }
};

export const loginMoodle = async ({ username, password }) => {
  try {
    const { token } = await login({ username, password });
    const user = await getCurrentUser({ token, username });
    return { ...user, token };
  } catch (e) {
    throw new Error(e);
  }
};
