import axios from "axios";
import { moodleURL } from "../api";

export const fetchNotifications = async ({ token }) => {
  try {
    delete axios.defaults.headers.common['Authorization'];
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=message_popup_get_popup_notifications&useridto=0`
    );
    return data;
    let output = data.notifications.filter((d) => !d.read);
    return output;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const fetchNotificationsCount = async ({ token }) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=message_popup_get_unread_popup_notification_count&useridto=0`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
