import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/styles.css";
import { AuthProvider } from "./contexts/auth";
import { MallasProvider } from "./contexts/mallas";
import { MoodleProvider } from "./contexts/moodle";
import { SnackProvider } from "./contexts/snack";
import {OdinProvider} from "./services/odin/auth";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Application = ({ children }) => {
  return (
    <AuthProvider>
      <MoodleProvider>
      <OdinProvider>
        <SnackProvider>
          <MallasProvider>{children}</MallasProvider>
        </SnackProvider>
          </OdinProvider>
      </MoodleProvider>
    </AuthProvider>
  );
};

root.render(
  <Application>
    <App />
  </Application>
);
