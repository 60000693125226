import axios from "axios";
import {
    odinBaseUrl,
    odinApiBaseUrl,
    odinAppClientId,
    odinAppClientSecret,
    odinAppGrantType,
    oOdinAppClientId
} from "../api";
import {getCurrentUser} from "../moodle/auth";
import React, {createContext, useContext, useState} from "react";
import {SNACKTYPES, useSnack} from "../../contexts/snack";

const OdinContext = createContext(null);
/* auth_userkey_request_login_url */

export const OdinProvider = ({children}) => {


    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [coursesHistorical, setcoursesHistorical] = React.useState(null);

    let actions = {};

    actions.loadData = function (load) {
        setLoading(load)
    };

    actions.userData = function (user) {
        setUser(user)
    }
    actions.passwordData = function (passw) {
        setPassword(passw)
    }

    actions.loginOdin =  function (username, password) {
        let tokenO = localStorage.getItem('id_token')
        let url = odinApiBaseUrl + 'merkademia/login'
        let userLogin = localStorage.getItem('loginUser')
        userLogin = btoa(userLogin)
        let data: string = ''
        if (!tokenO) {
            setLoading(true)
            const respToken = axios.post(`${odinBaseUrl}oauth/token`, {
                client_id: oOdinAppClientId,
                client_secret: odinAppClientSecret,
                scope: '*',
                grant_type: odinAppGrantType,
            }).then(async res => {
                // setTokenRequest( false)
                if (res.status === 201 || res.status === 200) {
                    window.localStorage.removeItem('id_token')
                    window.localStorage.setItem('id_token', res.data.access_token)
                    axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`

                    const respAuth = await axios.post(
                        `${url}`, {
                            username: username,
                            password: password,
                        }, {
                            headers: {
                                'Authorization': `Bearer  ${res.data.access_token}`
                            }
                        }
                    ).then( async resp=>{
                        data = JSON.stringify(resp.data);
                        setLoading(false)
                        }
                    );

                    if (data) {

                        localStorage.removeItem("loginUserOdin");
                        localStorage.setItem('loginUserOdin', data)
                    }
                    if (data.error) {
                        setLoading(false)
                        throw new Error(data.error);

                    }

                }
            })
        }
        const {token} = data;
        return data;

    };

    actions.dataOdinCourse = async function () {
        setLoading(true)
        let dataUserOdin = localStorage.getItem('loginUserOdin')
        let data: []
        let usuario_alumno = ''
        let api_token = ''

        if (!dataUserOdin) {
           //let value = await loginOdin()
            //console.log(value, 'value')
            //value = JSON.parse(value)
            //usuario_alumno = value.user.usuario_alumno
            //api_token = value.api_token
        }
        else {
            const userOdin = JSON.parse(dataUserOdin)
            usuario_alumno = userOdin.user.usuario_alumno
            api_token = userOdin.api_token
        }

        let urlOdin = odinBaseUrl + 'api/camps/user-courses?username=' + usuario_alumno
        await axios.get(
            `${urlOdin}`, {
                headers: {
                    'Authorization': `Bearer  ${api_token}`
                }
            }
        )
            .then(async response => {
                data = response.data.data
                setLoading(false)
            })
            .catch(error => {
                //setError(error);
                console.error('Error fetching data:', error);
                setLoading(false)
            });
        return data
    };

    let values = {
        loading,
        coursesHistorical,
        user,
        password,
        actions,
        dataOdin: actions.dataOdin,
        loginOdin: actions.loginOdin,
        dataOdinCourse: actions.dataOdinCourse
    };
    return (
        <OdinContext.Provider value={values}>{children}</OdinContext.Provider>
    );
};

export const useOdin = () => React.useContext(OdinContext);

