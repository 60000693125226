import React, { useState } from "react";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [verify, setVerify] = useState(false);

  let actions = {};

  actions.Auth = function (payload) {
    localStorage.setItem("@userdata", JSON.stringify(payload));
    setUser(payload);
  };

  actions.VerifyAuth = function () {
    let userData = JSON.parse(localStorage.getItem("@userdata"));
    if (userData) {
      setUser(userData);
    }
    setVerify(true);
  };

  actions.Logout = function (moodle = null) {
    if (user && user.id) {
      localStorage.setItem("newaccess", user.id);
    }
    setUser(null);
    if (moodle.categories || moodle.courses || moodle.grades) {
      moodle.setCourses(null);
      moodle.setCategories(null);
      moodle.setGrades(null);
    }
    localStorage.removeItem("@userdata"); //
    localStorage.removeItem("token");
    localStorage.removeItem("refresh"); //para refrescar al cambio de cuenta
    localStorage.removeItem("username");
    localStorage.removeItem("last-access-cmid");
    localStorage.removeItem("sence_session");
    localStorage.removeItem("current_course");
    localStorage.removeItem("last_login_sence");
    localStorage.removeItem("darkMode");
    localStorage.removeItem("idSence");
    localStorage.removeItem("senceSession");
    localStorage.removeItem("nru");
    localStorage.removeItem("loginUserOdin");
    localStorage.removeItem("id_token");
  };

  let value = {
    user,
    setUser,
    verify,
    actions,
    logout: actions.Logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => React.useContext(AuthContext);
