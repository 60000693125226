import React, { useEffect, useRef, useState } from "react";
import showPass from "../../assets/images/show-password.png";
import hidePass from "../../assets/images/hide-password.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./styles.css";
import { TipddyFooter } from "../../components/TipddyFooter";
import { useNavigate } from "react-router-dom";
import { DEFAULT_LOGIN } from "../../utils/state";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { loginMoodle } from "../../services/moodle/auth";
import { useAuth } from "../../contexts/auth";
import asec from "../../assets/images/logo-asec-blue-mobile.png";
import merkademia from "../../assets/images/logo-merkademia.png";
import PersonIcon from "@mui/icons-material/Person";
import handIcon from "../../assets/images/mano2.png";
import { useMoodle } from "../../contexts/moodle";
import {CustomSpinner} from "../../components/Loader";
import {useOdin} from "../../services/odin/auth";

export const Login = () => {
  // if (localStorage.getItem("@userdata")) window.location.replace("/mis-cursos");

  const [, setPasswordIcon] = useState(showPass);
  const [isVisible, setVisible] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [credentials, setCredentials] = useState(DEFAULT_LOGIN);
  const [widthLoginContainer, setWidthLoginContainer] = useState(0);
  const [widthLoginOdin, setWidthLoginOdin] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, handleSnack] = useSnack();
  const auth = useAuth();
  const navigate = useNavigate();
  const loginContainer = useRef(null);
  const moodle = useMoodle();
  const odin = useOdin();
  React.useEffect(() => {
    // localStorage.removeItem("token");
    auth.logout(moodle);
    // localStorage.removeItem(STRINGS.FORCEPASSWORD_ERROR_CODE);
  }, []);


  const handlePassword = (value) => {
    if (!value) {
      setPasswordIcon(showPass);
      setVisible(false);
      setPasswordType("password");
    } else {
      setPasswordIcon(hidePass);
      setVisible(true);
      setPasswordType("text");
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value.trim() });
  };

  const handleLogin = async () => {
    const isEmpty = Object.values(credentials).some((v) => v === "");

    if (isEmpty) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "LLene todos los campos",
        },
      });
      return;
    }
    try {
      setLoading(true);

      let isAuthorized = await loginMoodle(credentials);
      localStorage.removeItem("loginUser");
      //localStorage.setItem('loginUser', atob(credentials))
      odin.loginOdin(credentials.username,credentials.password)
      //odin.actions.passwordData(credentials.password)
      auth.actions.Auth(isAuthorized);
      setLoading(false);

      navigate("/mis-cursos");

      handleSnack({
        type: SNACKTYPES.success,
        payload: {
          message: "Ingreso exitoso!",
        },
      });
    } catch (e) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Error al validar la cuenta",
        },
      });
    }
  };

  const handleNumbers = () => {
    const users = document.getElementById("capacitados");
    const courses = document.getElementById("horas");
    const external = document.getElementById("clientes");

    function printCounter() {
      let userCounter = 759 - 409;
      let coursesCounter = 596 - 409;
      let externalCounter = 0;
      const data = [759, 596, 409];

      setInterval(() => {
        if (userCounter < data[0]) {
          userCounter++;
          users.innerText = `4.` + userCounter;
        }
        if (coursesCounter < data[1]) {
          coursesCounter++;
          courses.innerText = `209.` + coursesCounter;
        }
        if (externalCounter < data[2]) {
          externalCounter++;
          external.innerText = externalCounter;
        }
      }, 10);
    }

    printCounter();
  };

  useEffect(() => {
    handleNumbers();
    document.addEventListener("keypress", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("login-btn").click();
      }
    });
    document.getElementById("user-input").addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      false
    );
    document.getElementById("pass-input").addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
      },
      false
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loginContainer) {
      window.screen.width > 821 &&
        setWidthLoginContainer(loginContainer.current.offsetWidth);
    }
  }, [loginContainer]);

  const handleERAP = () => {
    window.open("https://diagnosticos.asecapacitacion.cl", "_blank");
  };

  return (
    <div className="login-box" tabIndex={0}>
      <div className="container-login-top">
        <img src={asec} alt="logo ASEC" className="logo-asec" />
        <img src={merkademia} alt="logo Merkademia" className="logo-asec" />
      </div>
      <div className="container-lg">
        <div className="row container-login-middle">
          <div className="col-lg-5 columna-0-padding d-flex justify-content-center">
            <div className="login-container" ref={loginContainer}>
              {/* <div
                className="login-erap"
                style={{
                  width:
                    widthLoginContainer > 0 ? `${widthLoginContainer}px` : "",
                }}
                onClick={() => handleERAP()}
              >
                <img
                  src={handIcon}
                  alt="hand icon"
                  className="login-hand-icon"
                />
                Realiza aquí tu evaluación de aprendizajes previos
              </div> */}
              <p className="text-white tit-login-container">Iniciar sesión</p>
              <div className="input-group" id="user-input">
                <input
                  type="text"
                  name="username"
                  className="form-control input-login"
                  placeholder="Usuario: 11111111-1"
                  onChange={handleChange}
                />
                <PersonIcon
                  className="img-show-hide-password"
                  sx={{ color: "#fff" }}
                  alt="icono ojo"
                />
              </div>
              <div className="input-group" id="pass-input">
                <input
                  type={passwordType}
                  name="password"
                  className="form-control input-login"
                  placeholder="Contraseña: Rm11111111-1"
                  onChange={handleChange}
                />
                {isVisible ? (
                  <VisibilityIcon
                    className="img-show-hide-password"
                    onClick={() => handlePassword(!isVisible)}
                    sx={{ color: "#fff", cursor: "pointer" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    className="img-show-hide-password"
                    onClick={() => handlePassword(!isVisible)}
                    sx={{ color: "#fff", cursor: "pointer" }}
                  />
                )}
              </div>
              <button
                type="button"
                id="login-btn"
                className="btn btn-primary btn-login"
                onClick={handleLogin}
              >
                Ingresar
              </button>
            </div>
          </div>
          <div className="col-lg-7 platform-data-container">
            <div className="platform-data right-bar text-center text-white">
              <img
                src={require("../../assets/images/ico-login-datos-1.png")}
                alt="ico-persona"
              />
              <p className="num-platform-data" id="capacitados">
                0
              </p>
              <p className="text-platform-data">Capacitados</p>
            </div>
            <div className="platform-data right-bar text-center text-white">
              <img
                src={require("../../assets/images/ico-login-datos-2.png")}
                alt="ico-libro"
              />
              <p className="num-platform-data" id="horas">
                0
              </p>
              <p className="text-platform-data">Horas de capacitación</p>
            </div>
            <div className="platform-data text-center text-white">
              <img
                src={require("../../assets/images/ico-login-datos-3.png")}
                alt="ico-mensaje"
              />
              <p className="num-platform-data" id="clientes">
                0
              </p>
              <p className="text-platform-data">Clientes</p>
            </div>
          </div>
        </div>
      </div>
      <TipddyFooter />
    </div>
  );
};
