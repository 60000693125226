import axios from "axios";
import { diagnosticoApiUrl } from "../api";
/* auth_userkey_request_login_url */

export const loginDiagnostic = async () => {
    let userData = JSON.parse(localStorage.getItem("@userdata"));
    console.log(userData, 'userData')
    if (userData) {
        let url = diagnosticoApiUrl
        const respAuth = await axios.post(
            `${url}`,{
                username : userData.username,
                password: userData.username,
                plataforma: 'alumno',
            }
        );
        const data = JSON.stringify(respAuth.data);
        if(data)
        localStorage.setItem('diagnostico', btoa(data))
        if (data.error) {
            throw new Error(data.error);
        }
        const { token } = data;
        return { data, token };
    }
    return null

};


